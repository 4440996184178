<template>
  <van-tabbar v-model="currpage">
    <van-tabbar-item v-for="(item,index) in tabbars" v-bind:key="index"
                     :class="tabpage == index ? 'changeColor':''"
                     @click="tab(index,item)"
                     :icon="item.icon" :to="item.to">
      {{ item.name }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: "CrmTabBar",
  props: {
    tabpage: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      currpage: this.tabpage,
      model: 0,
      currIndex: 0,
      active: 0,
      tabbars: [
        {
          name: "首页",
          index: 0,
          icon: "home-o",
          //子路由写了/代表绝对路径 跳转的时候 地址是这个http://localhost:8080/index
          //子路由没有写/ 代表的拼接的路径 跳转的时候地址是     http://localhost:8080/CustomerListEdit/index
          to: '/index'
        },
        {
          name: "走访计划",
          index: 1,
          icon: "friends-o",
          to: '/BulkCrmsTravelPlanList'
        },
        {
          name: "临时走访",
          index: 2,
          icon: "todo-list-o",
          to: '/BulkCrmsVisitTemporaryExistList'
        },
        {
          name: "走访复盘",
          index: 3,
          icon: "completed",
          to: '/BulkCrmsVisitReplayList'
        }
      ]
    }
  },
  methods: {
    tab(index, val) {
      console.log(val);
      this.currpage = index;
    }
  },
}
</script>

<style scoped>
.changeColor{
  color:#1989FA;
}
</style>
