<template>
  <div class="travel-plan-record-add">
    <div class="card">
      <van-row class="title">
        <van-col span="24">
          {{ info.customerName }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="24">
          授信等级：{{ data.level }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16">货量（万吨）：{{ data.weight }}</van-col>
        <van-col span="8" @click="to(data.lineDetail)">航线明细
          <van-icon name="share-o"/>
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="16">效益（万元）：{{ data.benefit }}</van-col>
      </van-row>
    </div>
    <div>
      <van-row class="info-title">基本信息</van-row>
      <van-form @submit="addReplay()">
        <van-row>
          <van-field
              readonly
              required
              label="客户："
              :value="info.customerName"
              v-model="info.customerName"
              placeholder="选择客户"
              input-align="left"
              @click="showCompanyName = false"
              label-width="6.5em"
          />
          <van-popup v-model="showCompanyName" round position="bottom">
            <van-field
                placeholder="在这里查询客户"
                v-model="selCustomerValue"
                left-icon="search"
            />
            <van-picker
                ref="picker"
                show-toolbar
                :columns="options"
                @cancel="showCompanyName = false"
                @confirm="onConfirmCompany"
                default-index="0">
            </van-picker>
          </van-popup>
        </van-row>
        <van-field style="border-radius: 0 0 0 0;" required label="标题："
                   :rules="costomerForm.title" placeholder="请输入标题"
                   v-model="info.title"></van-field>
        <van-field required label="出发时间：" v-model="info.startTime" placeholder="请选择计划出发时间"
                   :rules="costomerForm.startTime"
                   @click="show = true"/>
        <van-calendar v-model="show" @confirm="onConfirm" :show-confirm="false"/>
        <van-field required label="返回时间：" v-model="info.endTime" placeholder="请选择计划返回时间"
                   :rules="costomerForm.endTime"
                   @click="show1 = true"/>
        <van-calendar v-model="show1" @confirm="onConfirm1" :show-confirm="false"/>
          <van-button v-if="!isHidden" type="primary" size="large" native-type="submit">保存
        </van-button>
      </van-form>
    </div>
<!--    <crm-tab-bar :tabpage="1"/>-->
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";

export default {
  name: "FrequencyRemindGo",
  components: {CrmTabBar},
  data() {
    return {
      showCompanyName: false,
      isHidden: false,
      showPicker: false,
      maxDate: new Date(2030, 1, 1),
      lostDate: this.formatter(),
      currentDate: new Date(),
      data: {
        title: '',
        level: 'B',
        weight: '12.37万吨',
        benefit: '7.89万元',
        lineDetail: 'lineDetail',
        planDetail: 'planDetail'
      },
      info: {
        title: '',
        startTime: '',
        endTime: '',
        customerName: '',
        crmCustomerid: '',
        travelDate: this.formatter(),
        remarks: '',
      },
      date: '',
      date1: '',
      show: false,
      show1: false,
      options: [],
      columns: [],
      selCustomerValue: '',
      loading: false,
      costomerForm: {
        customerName: [
          {required: true, message: '请选择客户', trigger: 'blur'},
        ],
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
        ],
        startTime: [
          {required: true, message: '请选择出发时间', trigger: 'blur'},
        ],
        endTime: [
          {required: true, message: '请选择返回时间', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    //监听公司名头
    'selCustomerValue': function () {
      if (this.selCustomerValue == '') {
        this.options = []
        for (let i = 0; i < this.columns.length; i++) {
          var array = {}
          array.text = this.columns[i].customerName
          array.value = this.columns[i].crmCustomerid
          this.options.push(array)
        }
      } else {
        this.options = this.options.filter(item => item.text.includes(this.selCustomerValue))
      }
    },
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/bulkCrmsCusList/getBulkCrmsHfCusInfo.json',
          {
            crmCustomerid: this.$route.params.id
          }).then(res => {
        if (res.status == 200) {
          this.columns = res.data;
          if (res.data.length > 0) {
            this.info.customerName = res.data[0].customerName
            for (let i = 0; i < res.data.length; i++) {
              var array = {}
              array.text = res.data[i].customerName
              array.value = res.data[i].crmCustomerid
              this.options.push(array)
            }
            console.log(this.options)
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    to(to) {
      this.$router.push(to)
    },
    addReplay(formName) {
      let date = new Date();
      this.$ajax.post('/api/ajax/replay/saveBulkCrmReplayByGo.json',
          {
            plandateStart: this.info.startTime,
            plandateEnd: this.info.endTime,
            titleId: Math.random().toString(36).slice(2),
            crmCustomerid: this.$route.params.id,
            titleName: this.info.title,
            customerName: this.info.customerName,
            travelType: '003',
            createdPeople: this.$store.getters.userId
          }).then(res => {
        if (res.status == 200) {
          this.$message.success('保存成功！');
          setTimeout(() => {
            this.$router.replace({path: '/BulkCrmsVisitReplayList/'})
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('保存失败！');
      })

    },
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;
      return nowtime;
    },
    onConfirm(date) {
      this.show = false;
      this.info.startTime = this.formatDate(date);
    },
    onConfirm1(date) {
      this.show1 = false;
      this.info.endTime = this.formatDate(date);
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirmCompany(picker, value) {
      console.log(picker, value);
      this.info.customerName = picker.text;
      this.showCompanyName = false;
      this.getCompanyData(picker)
    },
    getCompanyData(picker) {
      this.info.crmCustomerid = picker.value
    },
  }
}
</script>

<style scoped>
.title {
  padding: 10px 5px;
  font-weight: 400;
  font-size: 18px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.info {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.travel-plan-record-add .card {
  background: #318EF5;
}

.info-title {
  padding: 10px 10px 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #AAA8A8;
  font-style: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}

.selectInfo .el-select {
  display: block;
}

</style>